<template>
  <base-modal-view title="Forgot Password" @cancel="cancelClicked">
    <div>
      <form v-if="!requested" @submit.prevent="saveClicked" class="horizontal-spacing flex-column">
        <p class="body-2 margin-vertical">A reset link will be emailed to this email if it is tied to an account</p>
        <p class="text-field-title margin-bottom-025">Email tied to your account</p>
        <input required type="text" v-model="email" class="text-field" placeholder="Email" />
        <p class="margin-left-auto margin-top-05 error" v-if="error">{{ error }}</p>
        <p class="margin-left-auto margin-top-05 error" v-if="email && !isValidEmail()">Must be a valid email</p>
        <primary-button type="submit" class="margin-top" :title="requesting ? 'Requesting...' : 'Request'" />
      </form>
      <div v-else class="flex-column">
        <p class="body-2 margin-vertical">A reset link has been sent to your email</p>
        <primary-button class="margin-top" title="Close" @click="cancelClicked" />
      </div>
    </div>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";
import { requestResetPassword } from "@/api/Auth.js";

export default {
  name: "ForgotPasswordModal",
  components: { PrimaryButton, BaseModalView },
  data: function () {
    return {
      email: "",
      error: null,
      requesting: false,
      requested: false
    };
  },
  methods: {
    cancelClicked: function () {
      this.$emit("cancel");
    },
    saveClicked: function () {
      if (!this.isValidEmail()) {
        return;
      }
      if (this.requesting) {
        return;
      }
      this.requesting = true;
      requestResetPassword(this.email)
        .then(() => {
          this.requesting = false;
          this.requested = true;
        })
        .catch(error => {
          this.requesting = false;
          var errorMessage = "";
          for (const [key, value] of Object.entries(error.response.data)) {
            errorMessage += key;
            errorMessage += ": ";
            errorMessage += value;
            errorMessage += "\n";
          }
          this.error = errorMessage;
        });
    },
    isValidEmail: function () {
      let re = /\S+@\S+\.\S+/;
      return re.test(this.email);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";

.spacer {
  background-color: $DIVIDER_GRAY_COLOR;
  border-left: 1px solid $DIVIDER_GRAY_COLOR;
}
</style>
