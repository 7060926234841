<template>
  <div class="flex-row">
    <img src="/assets/img/spash-login.jpg" class="splash" />
    <form @submit.prevent="onLoginPressed" class="form-container">
      <div class="pointer-cursor flex-row logo" v-on:click="goHome()">
        <img style="height: 100%" src="/assets/logo-yellow.svg" />
        <h1 class="h1-title margin-left vertical-flex-center">iAppraise</h1>
      </div>
      <h3 class="h3-title-secondary-color horizontal-flex-center margin-top-1-05 margin-bottom-1-05">
        Log in to your account
      </h3>
      <label class="label-secondary-color margin-bottom-025">Username</label>
      <input class="input-box" type="text" placeholder="Username" v-model="login.username" required />
      <label class="label-secondary-color margin-bottom-025 margin-top-075">Password</label>
      <input class="input-box" type="password" placeholder="Password" v-model="login.password" required />
      <div class="flex-row height-40">
        <p
          class="body-2-bold margin-left-auto vertical-flex-center pointer-cursor"
          v-on:click="showForgotPassword = true"
        >
          Forgot Password?
        </p>
      </div>
      <primary-button class="" :title="is_logging_in ? 'Signing In...' : 'Sign In'" type="submit" />
      <secondary-button
        v-if="!is_logging_in"
        icon="/assets/img/icon-azure.png"
        class="margin-top-auto margin-bottom margin-left-auto"
        title="Sign in with Azure"
        v-on:click="redirectToAzure"
      />
    </form>
    <forgot-password-modal @cancel="showForgotPassword = false" v-if="showForgotPassword" />
    <error-modal v-if="error" :errors="error" @cancel="modalCancelled" @cancelAll="modalCancelled" />
  </div>
</template>

<script>
import PrimaryButton from "../../components/Buttons/v2/PrimaryButton.vue";
import SecondaryButton from "../../components/Buttons/v2/SecondaryButton.vue";
import ErrorModal from "../../components/Modals/v2/ErrorModal.vue";
import ForgotPasswordModal from "../../components/Modals/v2/ForgotPasswordModal.vue";
import { mapActions } from "vuex";
import { login } from "../../api/Auth.js";
import { ssoConstants } from "@/api/axiosHandler.js";

export default {
  name: "Login",
  components: { PrimaryButton, SecondaryButton, ErrorModal, ForgotPasswordModal },
  data() {
    return {
      login: {
        username: "",
        password: ""
      },
      showForgotPassword: false,
      error: null,
      is_logging_in: false
    };
  },
  methods: {
    redirectToAzure: function () {
      window.location.replace(ssoConstants.azure);
    },
    onLoginPressed: function () {
      if (this.is_logging_in) {
        return;
      }
      this.is_logging_in = true;
      let isForSession = this.$route.query.session;
      login(this.login, isForSession)
        .then(data => {
          this.handleLoginResponse(data);
        })
        .catch(error => {
          this.is_logging_in = false;
          this.error = [error];
        });
    },
    handleLoginResponse(data) {
      this.is_logging_in = false;
      if (data.key) {
        this.setAuthToken(data.key);
      }
      if (data.profile) {
        let isDealership = data.profile.is_dealership;
        let isReseller = data.profile.is_reseller;
        let isSuperUser = data.profile.is_superuser;
        let next = this.$route.query.next;
        var routerPushName = "";
        var propogateNext = false;
        this.setIsDealership(isDealership);
        this.setIsSuperUser(isSuperUser);
        if (isDealership) {
          this.setCurrentDealership(data.dealership);
          routerPushName = "SwitchDealer";
          propogateNext = true;
        } else if (isReseller) {
          this.setCurrentReseller(data.reseller);
          routerPushName = "Search";
        } else if (isSuperUser) {
          routerPushName = "SuperUserPermissions";
        }

        if (next && !propogateNext) {
          this.$router.push(next);
        } else if (next && propogateNext) {
          this.$router.push({ name: routerPushName, query: { next: next } });
        } else {
          this.$router.push({ name: routerPushName });
        }
      }
    },
    goHome: function () {
      this.$router.push({ name: "Home" });
    },
    modalCancelled: function () {
      this.error = null;
    },
    ...mapActions({
      setAuthToken: "authStore/setAuthToken",
      setIsDealership: "authStore/setIsDealership",
      setIsSuperUser: "authStore/setIsSuperUser",
      setCurrentDealership: "dealershipStore/setCurrentDealership",
      setCurrentReseller: "resellerStore/setCurrentReseller"
    })
  },
  mounted: function () {
    let isForSession = this.$route.query.session;
    if (isForSession) {
      login({}, true)
        .then(data => {
          this.handleLoginResponse(data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/v2/base.scss";

* {
  padding: 0px;
  margin: 0px;
}

// Required for margin reset
p {
  margin: 0px;
}

body {
  margin: 0px !important;
  padding: 0px;
}

.splash {
  max-height: 100vh;
}

.form-container {
  @extend .horizontal-flex-center;

  margin-top: 200px;
  display: flex;
  flex-direction: column;
}

.logo {
  height: 96px;
}

.input-box {
  @extend .margin-top-025;

  padding: 11px 10px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  font: normal 14px $FONT_SF_PRO_DISPLAY_REGULAR;
  color: #262626;
}
</style>
